@import '../sass/var';
@import '../sass/vendor';

body {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    background-color: #f7f7f7;
}

a,
a:hover {
    text-decoration: none;
}

.edit-mode {
    position: relative;
    z-index: 10;
    background: white;
}

.table-header-feedbill {
    position: sticky;
    top: 0;
    z-index: 20;
    background: white; /* Ensure background is not transparent */
}

h2,
h5 {
    color: $heading-color;
    font-weight: 700;
}

.lot-summary-header {
    color: $heading-color;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.2;
}

.refresh-network-header {
    color: $heading-color;
}

.feedbills-buttons {
    height: 1% !important;
    justify-content: space-evenly;
}

.feedbills-buttons button {
    white-space: nowrap;
    padding: 6px 12px;
    margin-left: 8px;
}

.filter-by h5 {
    font-weight: 400;
    font-size: 24px;
}

.table-header-custom {
    font-size: 0.9rem;
    white-space: nowrap;
}

.modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.modal-title-container {
    flex: 1;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.right-aligned-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: normal;
    word-break: break-word;
    margin-right: 5px;
    margin-left: 5px;
}

.customer-name-overflow {
    display: inline-block;
    max-width: 15.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    margin-right: 2px;
}

.right-aligned-info-feedbill {
    display: flex !important;
    flex-direction: column !important;
    margin-left: auto;
    align-items: left;
    font-size: 0.875rem;
    color: #6c757d;
    margin-right: 10px;
}

.feed-bill-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    width: 100% !important;
}

.custom-input {
    padding: 5px;
    margin: 0 auto;
}

.pencil-container {
    cursor: pointer;
}

h4.form-heading {
    color: $heading-color;
    font-weight: 700;
    padding-left: 18px;
}

.main-container button,
.auth-container button {
    background-color: $secondary-color;
    color: $main-color;
    border-radius: 10px;
    padding: 8px 15px;
    font-weight: 600;
    border: none;

    &:hover {
        background-color: $main-color;
        color: #fff;
    }

    &:focus {
        background-color: $secondary-color;
        color: $main-color;
        box-shadow: none;
        border: none;
    }

    &:active {
        background-color: $secondary-color;
        color: $main-color;
    }

    &.btn-active {
        background-color: $main-color;
        color: #fff;
    }
}

.main-container button.secondary,
.auth-container button.secondary {
    background-color: $main-color;
    color: #fff;

    &:hover {
        background-color: $secondary-color;
        color: $main-color;
    }
}

.feedbill-line-buttons {
    margin-left: 4px;
    margin-top: -2px;
    display: flex;
    margin-right: 5px;
}

button.tertiary {
    background-color: #fff !important;
    color: $main-color !important;
    border: $main-color !important;
    border-style: solid !important;
    border-width: 0.1rem !important;

    &:hover {
        background-color: $main-color !important;
        color: #fff !important;
        border: #fff !important;
        border-style: solid !important;
        border-width: 0.1rem !important;
    }
}

button.support-modal {
    background-color: #fff !important;
    color: $main-color !important;
    border: #fff !important;
    border-style: solid !important;
    border-width: 0.1rem !important;

    &:hover {
        text-decoration: underline;
    }
}

.main-container button.square-button {
    padding: 0.25rem 0.75rem;
}

.react-datepicker__navigation {
    background: none !important;
}

.chargeout-table {
    color: $main-color;

    & .scroll-chargeoutTable {
        overflow-y: scroll;
        height: 108px;
    }

    & .chargeout-headers {
        font-weight: 900;
    }

    & .chargeout-price-header {
        span {
            font-weight: 900;
        }
    }

    & .chargeout-dose-column {
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}

.upload-button {
    color: $main-color !important;
    background-color: white !important;
    border: $main-color solid 2px !important;
    border-radius: 10px;
}

.user-manual-link {
    color: #000000;
    margin-top: -25px !important;
}

.drag-drop-box {
    margin-top: 2%;
    width: 200px;
    height: 80px;
    border: 2px dashed #5a5a5a;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: #5a5a5a;
    font-size: 14px;
    text-align: center;
}

.support-modal-delete-icon {
    width: 20px !important;
    height: 20px !important;
}

.drag-drop-box:hover {
    background-color: #e0e0e0;
    border-color: #007bff;
    color: #007bff;
    cursor: pointer;
}

.indeterminate-symbol {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.indeterminate {
    background-color: $main-color !important;
}

.checked {
    background-color: $main-color !important;
}

.unchecked {
    background-color: white !important;
}

.hedge-confirmation-model {
    max-width: 800px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 25%;
}

.search-box {
    width: 300px;
}

.checkbox-wrapper {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
}

.search-box input {
    border: 1px solid #ced4da;
    outline: none;
}

.search-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #5d5d5d;
    cursor: pointer;
}

.checkbox-container {
    position: relative;
    display: inline-block;
    width: 21px;
    height: 21px;
}

.checkbox-container input {
    background-color: white;
}

.checkbox-indeterminate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.closed-hedge-footer {
    display: flex;
    padding: 10px 0;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;
    gap: 20px;
}

.custom-checkbox-negative {
    appearance: none;
    width: 20px !important;
    height: 20px !important;
    background-color: white;
    border: 2px solid #000;
    border-radius: 2px !important;
    cursor: pointer;
    display: inline-block;
}

.custom-checkbox-negative.checked {
    background-color: $main-color;
    border-color: $main-color;
}

.custom-checkbox-negative.unchecked {
    background-color: white;
    border-color: #000;
}

#content {
    position: relative;

    & .mainLoading {
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.main-content button.add-row-btn {
    background-color: $main-color;
    color: #fff;
    border-radius: 10px;
    padding: 4px 18px;
    font-size: 12px;
    font-weight: 400;
    border: none;
    height: 26px;
}

.main-content label {
    display: block;
    margin-top: 10px;
    color: $heading-color;
}

.main-container input,
.main-container textarea,
.main-container select {
    background-color: #f0f0f0;
    font-family: 'Josefin Sans', sans-serif;
    border-radius: 4px;
    border: 1px solid #f0f0f0;
    padding: 4px 8px;
    min-height: 32px;
    font-size: 14px;
}

.main-container input::placeholder {
    font-style: italic;
}

.main-container input[type='file'] {
    max-width: 110px !important;
}

select {
    -webkit-appearance: none;
    appearance: none;
    background-image: url(../dropdown_icon.png);
    background-repeat: no-repeat;
    background-position: 96% center;
}

.disabled,
input:disabled,
textarea:disabled,
select:disabled,
input[readonly],
textarea[readonly],
select[readonly] {
    background-color: #cccccc !important;
}

input.checkbox-readonly {
    background-color: #f7f7f7 !important;

    &:checked {
        background-color: #242748 !important;
    }
}

//Side-Navigation

.sidenav-caret {
    position: fixed;
    top: 50%;
    left: 300px;
    transform: translate(0px, -50%);
    z-index: 2;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 2px 2px 6px rgb(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;

    & a {
        display: flex;
        justify-content: center;
        color: $main-color;
        position: relative;
        top: 5px;
        right: 2px;
        font-size: 18px;
    }

    &.caret-slide-left {
        left: 10px;
    }

    &.caret-slide-left a {
        transform: rotate(180deg);
        right: 0;
    }
}

.sidenav {
    position: fixed;
    width: 315px;
    height: 100vh;
    background-color: $main-color;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    overflow-y: scroll;
    transition: 0.3s;

    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    & .sidebar-logo {
        display: flex;
        justify-content: center;
        padding: 40px 0px;

        & img {
            width: 150px;
        }
    }

    &.sidebar-shrink {
        width: 24px;

        & .sidebar-logo {
            visibility: hidden;
        }

        & .sidebar-tabs {
            visibility: hidden;
        }

        & .accordion {
            visibility: hidden;
        }
    }

    & .sidebar-tabs button {
        width: 100%;
        border: none;
        background-color: rgba(0, 0, 0, 0.16);
        color: #fff;
        padding: 8px 4px;
        font-size: 14px;

        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }

        &:hover,
        &.active {
            background-color: $secondary-color;
            color: $main-color;
        }
    }

    & .accordion {
        &.accordion-secondary {
            margin-top: 0;

            & .accordion-button {
                border-radius: 10px;

                &:hover,
                &.active {
                    background-color: $secondary-color;
                    color: $main-color;
                }

                &:hover img,
                &.active img {
                    filter: brightness(0.3) invert(0);
                }
            }
        }

        & .accordion-item {
            background: none;
            border: 0;

            & .create-button button {
                background-color: $secondary-color;
                color: $main-color;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 800;
                margin-bottom: 10px;

                & i {
                    margin-right: 8px;
                    position: relative;
                    bottom: 1px;
                    color: $main-color;
                    font-weight: 800;
                }
            }

            & .accordion-collapse {
                margin-top: 10px;
            }

            & button {
                background: none;
                color: #fff;
                font-size: 18px;
                box-shadow: none;
                border-radius: 10px;

                &:hover,
                &.active {
                    background-color: $secondary-color;
                    color: $main-color;
                }

                &:hover img,
                &.active img {
                    filter: brightness(0.3) invert(0);
                }

                & img {
                    margin-right: 10px;
                    position: relative;
                    bottom: 3px;
                }

                &::after {
                    display: none;
                }
            }

            & .accordion-body {
                padding: 0px 4px;
                list-style-type: none;
                padding-left: 28px;
                padding-bottom: 15px;

                & .accordion-subitem {
                    color: #fff;
                    border-left: rgba(255, 255, 255, 0.2) 3px solid;
                    border-radius: 0;
                    transition: 0.3s;

                    &.active {
                        color: $secondary-color;
                        border-left: $secondary-color 3px solid;
                    }

                    &:hover {
                        color: $secondary-color;
                        border-left: $secondary-color 3px solid;
                    }

                    &:hover li {
                        color: $secondary-color;
                    }

                    & a,
                    .buypo-nav-subitem-link {
                        display: inline-block;
                        color: #fff;
                        width: 90%;
                        padding: 8px 15px;
                    }
                }
            }
        }
    }

    & .sidebar-tabs {
        margin-bottom: 30px;
    }

    & .sidebar-tabs button {
        width: 100%;
        border: none;
        background-color: rgba(0, 0, 0, 0.16);
        color: #fff;
        padding: 8px 4px;
        font-size: 14px;

        &:first-child {
            border-radius: 10px 0px 0px 10px;
        }

        &:last-child {
            border-radius: 0px 10px 10px 0px;
        }

        &:hover,
        &:active {
            background-color: $secondary-color;
            color: $main-color;
        }
    }

    & .user-profile {
        display: flex;
        align-items: center;
        padding: 20px 15px;
        border-top: 1px solid #666666;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        background-color: $main-color;
        z-index: 2;

        & .user-profile-img {
            & img {
                width: 50px;
                height: 50px;
                background-color: $secondary-color;
                border-radius: 50%;
                object-fit: cover;
            }
        }

        & .user-profile-detail {
            color: #fff;
            padding-left: 8px;

            & h4 {
                margin: 0;
            }

            & p {
                margin: 0;
                margin-top: 6px;
            }
        }
    }
}

.custom-toggle {
    margin-top: 10px !important;
}

.custom-toggle .toggle-switch {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 20px !important;
}

.custom-toggle .toggle-label {
    font-size: 16px;
    color: #000000;
}

.custom-toggle .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.custom-toggle .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.custom-toggle .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.custom-toggle .slider::before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.custom-toggle .switch input:checked + .slider {
    background-color: #093bb8;
}

.custom-toggle .switch input:checked + .slider::before {
    transform: translateX(20px);
}
//Main-Content-Conatainer

.main-container {
    width: calc(100% - 315px);
    padding: 0px;
    background-color: #f7f7f7;
    margin-left: auto;
    padding-bottom: 40px;
    min-height: 100vh;
    transition: 0.3s;

    &.main-container-expand {
        width: calc(100% - 25px);
    }
}

.format-total-row {
    margin-left: -10px;
    margin-top: -20px;
}

.lot-page-modified-design {
    margin: 5px;
    top: -10px;
}

.reset-button-new {
    margin-top: 38px;
    margin-left: 10px;
    height: 33px !important;
}

.custom-font-size {
    font-size: small;
}

.sticky-bottom-row {
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.breadcrumb-alignment {
    margin-top: 2px;
}

.footer-edit-feedbill {
    margin-top: -30px;
    margin-left: -10px;
}

.main-content-yard-layout {
    padding: 0px 10px;
    background-color: white;
    min-height: calc(100vh - 100px);

    & .yard-layout-filter {
        max-width: 100px;
    }

    & .yard-layout-div {
        width: 10px;
        height: 20px;
        border: 1px solid black;
        box-sizing: border-box;

        &.selected-pen {
            background-color: #ffd398;
        }

        &.hospital-pen {
            margin-left: 31.5px;
            background-color: #142851;
        }

        &.receiving-pen {
            background-color: #be3f2f;
        }

        &.buller-pen {
            background-color: #6f5f14;
        }

        &.home-pen {
            background-color: #1b740c;
        }

        &.empty-pen {
            margin-left: 49.5px;
            background-color: #81189a;
        }
    }

    & .yard-layout-block {
        overflow-x: scroll;
        overflow-y: scroll;
        min-height: calc(100vh - 230px);
        padding: 20px;
    }

    & .yard-layout-grid {
        width: max-content;
    }

    & .zoom-in-button {
        padding-right: 20px;
        padding-left: 20px;
    }

    & .reset-button {
        width: 115px;
    }

    & .yard-layout-one-pen {
        width: 50px;
        height: 50px;

        & .pen-details {
            font-size: 16px;
        }

        & .card-body {
            overflow: hidden;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.costperhead-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -10px;
}

.narrow-input {
    width: 12vw;
    text-align: center;
    margin: 0 auto;
}

.custom-modal {
    margin-right: 70%;
}

.cost-head {
    margin-left: auto;
    max-width: 50%;
}

#excludeSold input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    min-height: 22px !important;
}

#modify-excludelot {
    margin-top: -90px;
    margin-right: 10px;
    display: flex;
}

#modify-excludelot label {
    margin-right: 10px;
}

#excludeSold {
    display: flex;
    margin-right: 10px;
}

#excludeSold label {
    margin-right: 10px;
    white-space: nowrap;
}

.shadow {
    font-size: 13px;
}

.pencil {
    width: 20px;
}

.lot-filter {
    margin-top: -2.8rem;
    margin-left: auto;
    width: max-content;
    border: none;
}

.right-button {
    width: 40px;
    margin-left: auto;
    margin-right: 3px;
    border-radius: 50px !important;
}

.spreadsheet-detail-box {
    border: #e8e8e8 1px solid;
    width: 100px !important;
    min-height: 32px !important;
    text-align: center;
    font-size: 15px !important;
    overflow-x: scroll;
}

.spreadsheet-heading {
    font-size: 25px !important;
    color: $shawna-spreadsheet-heading-color;
    font-weight: bold;
}

.spreadsheet-feedbill-amount {
    margin-left: 6.3vw !important;
    font-size: 12px;
    span {
        font-weight: 500;
    }
    span::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}

.spreadsheet-sold-total {
    margin-left: 110px !important;
    font-size: 12px;
    span {
        font-weight: 400;
        font-size: 12px;
        overflow-x: scroll;
    }
    span::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .shift-price {
        margin-left: 120px;
        margin-right: 30px;
    }
}

.spreadsheet-month-list {
    margin-left: 30px;
    display: inline-block;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    font-size: 14px;
    width: max-content;
}

.month-heading {
    font-weight: bold;
    color: $shawna-spreadsheet-heading-color;
}

.spreadsheet-monthlist-total {
    font-size: 13px;
    margin-top: -20px;
}

.spreadsheet-header-details {
    span {
        font-weight: bold;
        font-size: 16px;
        color: $shawna-spreadsheet-heading-color;
    }
}

.feedbill-detail {
    font-weight: bold;
    font-size: 14px;
    color: $shawna-spreadsheet-heading-color;
    margin-left: 20px;
}

.total-value-div {
    margin-left: 10px;
    font-size: 14px;
    max-width: 90px;
    overflow-x: scroll;
    span {
        font-weight: bold;
        font-size: 15px;
        color: $shawna-spreadsheet-heading-color;
    }
}

.scroll-horizontal {
    overflow-x: scroll;
    max-width: 5vw;
}

.box-overflow {
    overflow-x: scroll;
    width: 50px;
}

.box-overflow::-webkit-scrollbar,
.scroll-horizontal::-webkit-scrollbar,
.spreadsheet-detail-box::-webkit-scrollbar,
.purchase-card-design::-webkit-scrollbar,
.spreadsheet-month-list::-webkit-scrollbar,
.total-value-div::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.total-purchase-price {
    margin-left: 47px !important;
}

.monthlist-size {
    width: 75%;
    overflow-x: scroll;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.monthlist-size::-webkit-scrollbar {
    height: 6px;
}

.monthlist-size::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #cccccc;
    visibility: hidden;
}

.monthlist-size::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 10px;
}

.dollor-sign {
    margin-right: 6px !important;
    text-align: left;
}

.flex-item {
    width: 1000px;
    flex: 1;
    text-align: center;
}

.heading-total {
    font-weight: bold !important;
    font-size: 15px !important;
    color: $shawna-spreadsheet-heading-color;
}

.purchase-card-body {
    height: 180px !important;
}

.table-box-with-sticky-footer .tbody::-webkit-scrollbar {
    width: 2px;
}

.table-box-with-sticky-footer .tbody::-webkit-scrollbar-track {
    background: #fff;
}

.table-box-with-sticky-footer .tbody::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.table-box-with-sticky-footer {
    position: relative;
}

.table-box-with-sticky-footer .tbody {
    position: relative;
    height: 200px;
    overflow-y: scroll;
    width: 100%;
}

.table-box-with-sticky-footer .thead,
.table-box-with-sticky-footer .tbody-row,
.table-box-with-sticky-footer .tfoot {
    display: flex;
}

.table-box-with-sticky-footer .thead {
    position: sticky;
    top: 0px;
    background: #fff;
}

.table-box-with-sticky-footer .thead .column {
    width: 100%;
    color: $shawna-spreadsheet-heading-color;
    padding: 10px 0 10px 10px;
    font-size: 12px;
}

.table-box-with-sticky-footer .tbody-row .column,
.table-box-with-sticky-footer .tfoot .column {
    color: $text-color;
    width: 100%;
    padding: 10px 0 10px 10px;
    font-size: 12px;
}

.full-page-app-loader {
    min-height: 100%;
}

.table-box-with-sticky-footer .tbody-row:nth-of-type(odd) {
    background: rgba(0, 0, 0, 0.05);
    color: #212529;
}

.table-box-with-sticky-footer .column p {
    margin: 0px;
}

.table-box-with-sticky-footer .tfoot {
    background: #fff;
    width: 100%;
}

.table-box-with-sticky-footer .thead .column {
    font-size: 14px;
    font-weight: bold;
    color: $shawna-spreadsheet-heading-color;
}

.purchase-card-design {
    width: 100%;
    height: 200px;
    overflow-y: scroll;
    padding: 3px !important;
}

.plus-icon {
    font-size: 0.6rem;
}

.spreadsheet-list-style {
    font-size: 14px;
    color: $shawna-spreadsheet-heading-color;
}

.cancel-button {
    width: 40px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 50px !important;
}

.total-amount-div {
    width: 4vw;
}

.margin-top {
    margin-top: -2%;
    margin-left: auto;
}

.display {
    border: #e8e8e8 1px solid;
    padding: 7px;
}

.amount-align {
    display: flex;
    justify-content: space-between;
    height: 22px;
}

.purchase-div-size {
    width: 50%;
}

.lot-summary-table {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
}

.lot-summary-table td,
.lot-summary-table th {
    text-align: center;
    padding: 8px;
    font-size: 14px;
    font-size: 12px;
    min-width: 100px;
}

.lot-summary-table .font-weight-bold {
    font-weight: bold;
}

.lot-summary-table .text-end {
    text-align: end;
}

.lot-summary-table .overflow-block-auto {
    max-height: 300px;
    overflow-y: auto;
}

.setting-clear-filter {
    margin-top: 36px;
    border-radius: 10px !important;
    margin-left: 10px;
    margin-right: 10px;
    height: 43px;
}

.floating-action-button {
    position: fixed;
    border-radius: 50% !important;
    bottom: 20px;
    right: 22px;
    width: 40px;
    height: 40px;
    z-index: 1000;

    & .floating-action-button-arrow {
        position: fixed;
        bottom: 32px;
        right: 35px;
        z-index: 1001;
    }
}

.truncated-input {
    width: 40px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.manage-contracts-heading {
    min-width: 12rem;
    padding: 0 1em !important;
}

.contracts-checkbox-input {
    width: 20px;
}

.contracts-checkbox-input {
    width: 20px;
}

.contract-summary {
    height: 1rem;
}

.filter-multi-select-lot {
    border-radius: 1em;
    border-width: 1px;
    border-style: solid;
    background-color: #f0f0f0;
    border-color: #979797;
}
.filter-multi-select-lot:hover {
    background-color: #dddddd;
    cursor: pointer;
}

.main-content {
    padding: 0px 20px;

    & .main-content-block {
        border-radius: 10 px;

        & .white-content-block-min-height {
            min-height: calc(100vh - 230px);
        }

        & .white-content-block {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            padding: 12px 0px;
            margin-bottom: 18px;
            position: relative;

            & .fixed-row-header {
                position: sticky;
                top: 0;
                background-color: #ffffff;
                z-index: 1;
            }

            & .filter-by {
                padding: 0px 15px;
            }

            & .filter-by-inner {
                margin-top: 15px;

                & .form-item input {
                    min-width: 181px;
                    width: 100%;
                }
            }

            & .filter-by-inner-buy-po {
                margin-top: 15px;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                max-width: 100%;

                & .form-item {
                    padding-right: 2px;

                    & input {
                        min-width: 50px;
                        width: 100%;
                    }

                    & .buy-po-date-picker {
                        min-width: 98%;
                        width: 50%;
                    }
                }
            }

            & .scale-ticket-form,
            .manual-feed-entry-form,
            .add-adjustment-form {
                & .col {
                    padding: 0px 8px;
                }
            }

            & .manual-feed-entry-form-add-row {
                & div button {
                    position: absolute;
                    right: 0px;
                    margin-right: 10px;
                }
            }

            & .manual-feed-entry-form-add-row-old {
                position: relative;

                & div button {
                    position: absolute;
                    right: 0px;
                    margin-right: 10px;
                }
            }

            & .treatments-form-row {
                display: flex;
            }

            & .treatments-form-col {
                flex: 50%;
            }

            & .treatments-form {
                & .col {
                    padding: 0px 8px;
                }

                & .give-record-section,
                .history-section {
                    padding: 0px 15px;
                    margin-top: 40px;
                }

                & .give-record-header,
                .history-header {
                    background-color: $main-color;
                    padding: 10px 15px;
                    border-radius: 5px 5px 0px 0px;

                    & h4 {
                        color: #fff;
                    }

                    & button {
                        font-size: 16px;
                        padding: 5px 10px;
                    }
                }

                & .give-record-body,
                .history-body {
                    border: 1px solid #c6c6c6;
                    border-radius: 0px 0px 5px 5px;

                    & div {
                        padding: 8px 15px;
                    }

                    & .give-record-item {
                        border-bottom: 1px solid #c6c6c6;
                    }
                }

                & .clean-date-block {
                    border: 1px solid black;
                    color: $main-color;
                    height: 150px;
                    border-radius: 5px 5px 5px 5px;
                    margin-top: 40px;
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & span {
                        font-weight: 900;
                    }
                }

                & .clean-date-present-past {
                    background-color: #31b71b80;
                    border: 1px solid #31b71b80;
                }

                & .clean-date-future {
                    background-color: #d8000080;
                    border: 1px solid #d8000080;
                }
            }

            & .load-tickets-scroll-div {
                min-height: 550px;
                max-height: 550px;
                overflow-y: scroll;
                padding: 0px 20px;

                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #cccccc;
                    visibility: hidden;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b9b9b9;
                    border-radius: 10px;
                }
            }

            & .feed-route-creation-scroll-div {
                min-height: 70px;
                max-height: 200px;
                overflow-y: scroll;
                padding: 0px 20px;
                border-left: 1px solid #dedede;

                &::-webkit-scrollbar {
                    width: 6px;
                    height: 6px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #cccccc;
                    visibility: hidden;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b9b9b9;
                    border-radius: 10px;
                }
            }

            & .feedCall-info-scroll-div {
                max-height: 50px;
                overflow-y: scroll;
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                }

                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #cccccc;
                    visibility: hidden;
                }

                &::-webkit-scrollbar-thumb {
                    background: #b9b9b9;
                    border-radius: 10px;
                }
            }

            .feed-call-info-items {
                background-color: #f7f7f7;
            }

            & #daily-charges-btn button {
                background-color: $secondary-color;
                color: $main-color;
                border-radius: 10px;
                padding: 8px 15px;
                font-weight: 600;
                border: none;
                box-shadow: none;

                &:hover {
                    background-color: $main-color;
                    color: #fff;
                }
            }

            .receive-cattle-fee-header h6,
            .costAmountTableTotal {
                color: $heading-color;
            }

            .invoice-preview-lot-detail span {
                display: inline-block;
                min-width: 180px;
            }

            p.invoice-preview-bill-to-label,
            p.invoice-preview-contact-info-label {
                min-width: 120px;
            }

            & .pen-ration-schedule-feed-call-basic-detail {
                .headcount-input {
                    width: 100px;
                }

                .comments-input {
                    width: 300px;
                }
            }

            & .pen-ration-schedule-feed-call {
                table {
                    margin: 0;

                    .customize-row {
                        font-size: 11px !important;
                        font-weight: 600 !important;
                    }

                    td {
                        padding-top: 5px !important;
                        padding-bottom: 5px !important;
                    }
                }
            }

            & .ration-filter-block {
                select {
                    padding-right: 30px;
                }
            }

            & .ration-filter-block-switch {
                & label {
                    margin-top: 0;
                }

                & .switch {
                    margin-top: 0;
                }

                & h6 {
                    margin-bottom: 0;
                }

                & .switch input {
                    min-width: fit-content;
                }
            }

            & .mixing-bins .col-6:first-child {
                border-right: 1px solid #dedede;
            }

            & .react-time-picker {
                width: -webkit-fill-available;

                & button {
                    display: none;
                }

                & input {
                    padding: 0px;
                    min-width: 0px !important;
                    min-height: 0px;
                    font-size: 14px;
                    font-weight: 400;

                    &:focus-visible {
                        box-shadow: none;
                    }
                }

                & select {
                    padding: 0px;
                    min-width: 20px !important;
                    min-height: 0px;
                    font-size: 14px;
                    font-weight: 400;
                }

                & .react-time-picker__inputGroup {
                    padding: 3px;
                }

                & .react-time-picker__wrapper {
                    background-color: #f0f0f0;
                    border-radius: 5px;
                    display: block;
                }

                & .react-time-picker__clock {
                    display: none;
                }

                & .react-time-picker__inputGroup__leadingZero {
                    padding: 0px 7px 0px 0px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            & .time-picker {
                display: flex;
                background-color: #f0f0f0;
                border-radius: 4px;

                & span {
                    font-size: 22px;
                    position: relative;
                    top: -1px;
                }

                & select {
                    margin: 0px 8px;
                    max-width: 70px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            .add-ingredient-add-btn {
                position: relative;

                & div {
                    position: absolute;
                    right: 0;
                    padding-right: 0;
                    top: 5px;
                }
            }

            .medicines-checkboxes {
                align-items: flex-start;

                & input {
                    width: 24px;
                    margin-right: 8px;
                }

                & label {
                    position: relative;
                    top: 2px;
                }
            }

            .processing-protocol-checkbox {
                align-items: flex-start;

                & input {
                    width: 24px;
                    margin-right: 8px;
                }

                & label {
                    position: relative;
                    top: 2px;
                    padding-right: 12px;
                    word-break: break-all;
                }
            }

            .processing-protocol-checkbox-fixed {
                align-items: flex-start;

                & input {
                    max-width: 24px;
                    min-width: 24px;
                    margin-right: 8px;
                }

                & label {
                    position: relative;
                    top: 2px;
                    padding-right: 12px;
                    word-break: none;
                }
            }

            .mortality-removal-checkbox {
                align-items: flex-start;

                & input {
                    width: 28px;
                    margin-right: 8px;
                }

                & label {
                    position: relative;
                    top: 2px;
                }
            }

            .lot-pen-section {
                .border-right {
                    border-right: 1px solid #ccc;
                }
            }

            .penShipList {
                border: 1px solid #dedede;
                border-radius: 10px;
                margin-bottom: 10px;
                padding: 10px 10px;

                & label {
                    font-weight: bold;
                }

                & .hot-cattle-count {
                    color: #ff0000;
                }
            }

            .shipCattle-lotComponent {
                border-right: 1px solid #dedede;
            }

            & .update-ship-cattle-summary {
                border-right: 1px solid #c6c6c6;
            }

            & .split-schedule-history-add-row {
                position: relative;

                & td {
                    position: absolute;
                    right: 20px;
                }
            }

            div#attach-files {
                position: relative;
                top: 10px;
            }

            .buypo-fileUpload {
                position: relative;
                top: 10px;
            }

            .processing-pen-headcount-list input[type='checkbox'] {
                width: 24px;
                position: relative;
                top: 4px;
            }

            & .cursor-pointer.active {
                box-shadow: 0px 0px 12px #00000059;
                background: $secondary-color;

                & td {
                    color: #fff;

                    & img {
                        filter: brightness(0) invert(1);
                    }
                }
            }

            .realizer-cattle-revenue {
                position: relative;
            }

            .realizer-cattle-revenue input {
                padding: 0px 16px;
            }

            .realizer-shipment-form-row {
                padding-right: 50px;

                .realizer-shipment-checkbox {
                    padding-top: 4px;
                    padding-left: 50px;

                    & input {
                        min-height: fit-content;
                        width: 25px;
                        height: 25px;
                    }
                }

                .payment-date {
                    padding-right: 100px;
                }

                .payment-date-filter {
                    margin-left: auto;
                    padding-right: 100px;
                }

                .realizer-shipment-form-row-delete-btn-deprecated {
                    position: absolute;
                    right: 100px;
                    width: fit-content;
                }

                .realizer-shipment-form-row-delete-btn {
                    position: absolute;
                    right: 24px;
                    width: fit-content;
                }
            }

            .realizer-cattle-revenue span {
                position: absolute;
                top: 6px;
                left: 18px;
                font-size: 14px;
            }
            .realizer-cattle-brand-cost {
                position: absolute;
                top: 6px;
                left: 5px;
                font-size: 14px;
            }

            & .make-current-ration-checkbox,
            .adjustment-feed-entries-checkbox {
                min-height: 18px;
                position: relative;
                top: -2px;
            }

            .custom-input-dropdown {
                position: relative;

                & img[alt='dropdown_icon.png'] {
                    position: absolute;
                    right: 8px;
                    top: 7px;
                }

                & ul {
                    list-style-type: none;
                    padding: 0px;
                    border: 1px solid rgba(0, 0, 0, 1);
                    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
                    position: absolute;
                    width: 100%;
                    background: #fff;
                    z-index: 2;
                    display: none;

                    & li {
                        padding: 0px 4px;
                        font-size: 12px;

                        &:hover {
                            background-color: #1e90ff;
                            color: #fff;
                        }

                        &.select-option {
                            background-color: #1e90ff;
                            color: #fff;
                        }
                    }

                    &.input-dropdown-show {
                        display: block;
                    }
                }
            }

            .custom-bunk-score-dropdown {
                font-weight: bold;
            }

            .bunk-score-row {
                position: relative;

                & .custom-bunk-score-loader {
                    position: absolute;
                    text-align: center;
                    background-color: rgba(0, 0, 0, 0.1);
                    width: 100%;
                    height: calc(100% + 20px);

                    :first-child {
                        margin-top: 0.4rem !important;
                        margin-bottom: 0px !important;
                    }
                }
            }

            & .search-commodity-adjustment-input {
                width: 119px;
            }
        }

        & .block-heading {
            margin: 0;
            color: $heading-color;
        }

        & .outer-heading {
            width: 50%;

            & a {
                color: $heading-color;
                font-size: 22px;

                & i {
                    -webkit-text-stroke: 1px #f7f7f7;
                }
            }

            & .block-heading {
                margin: 10px 0px;
            }
        }

        & .inner-heading {
            & .block-heading {
                padding-left: 20px;
            }
        }

        .feed-call-info-items {
            background-color: #f7f7f7;
        }

        & .button-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            margin-bottom: 20px;

            & h3 {
                margin: 0;
            }
        }

        & .select-search {
            padding: 15px 20px;

            & .row {
                padding-bottom: 20px;
                border-bottom: 1px solid #cccccc;
            }

            & .row .col-md-4 .form-item input {
                width: 100%;
            }

            & .row .col-md-4 .form-item select {
                width: 100%;
            }
        }

        & .table-scroll-div {
            max-height: 450px;
            overflow-y: scroll;
            word-break: break-word;
            position: relative;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #cccccc;
                visibility: hidden;
            }

            &::-webkit-scrollbar-thumb {
                background: #b9b9b9;
                border-radius: 10px;
            }
        }

        .lot-list-height {
            max-height: 60vh;
        }

        .no-arrow-input {
            ::-webkit-outer-spin-button,
            ::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        & .table-scroll-div-feedcall-route {
            height: 550px;
            overflow-y: scroll;
            word-break: break-word;
            position: relative;

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #cccccc;
                visibility: hidden;
            }

            &::-webkit-scrollbar-thumb {
                background: #b9b9b9;
                border-radius: 10px;
            }
        }

        .table-list-loader {
            background: rgba(0, 0, 0, 0.2);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
            left: 12px;
            display: flex;
            align-items: center;
            border-radius: 10px;
        }

        & .feed-call-table-scroll-div {
            max-height: calc(100vh - 340px);
            overflow-y: scroll;
            word-break: break-word;

            &::-webkit-scrollbar {
                width: 2px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #cccccc;
                visibility: hidden;
            }

            &::-webkit-scrollbar-thumb {
                background: #b9b9b9;
                border-radius: 10px;
            }

            & table tbody tr:nth-of-type(odd) {
                background-color: #d5d5d5;
            }
        }

        & .past-invoice-table-scroll-div {
            max-height: calc(100vh - 230px);
            overflow-y: scroll;
            word-break: break-word;

            & table {
                table-layout: fixed;
                width: 100%;
            }

            & .select-checkbox-th {
                width: 10%;
            }

            &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: #cccccc;
                visibility: hidden;
            }

            &::-webkit-scrollbar-thumb {
                background: #b9b9b9;
                border-radius: 10px;
            }
        }

        & .move-cattle-table {
            & label {
                font-size: 15px !important;
                margin-top: 8px;
                margin-bottom: 2px;
            }
            & thead {
                position: unset;
                vertical-align: middle;
            }

            & th {
                background-color: $heading-color;
                color: #fff;
            }

            & tr {
                border: solid 1px #dee2e6;
            }

            .small-width-col {
                width: 13rem;
            }

            .wrap-text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .large-width-col {
                width: 30rem;
            }

            .delete-warning-icon-width {
                width: 4rem;
            }

            & input[type='checkbox'] {
                border: none !important;
                border-radius: 20% !important;
                margin-top: 5px !important;
                margin-left: 30px !important;
            }
        }

        & table {
            & input {
                background-color: #f7f7f7;
                border-radius: 4px;
                border: 0;
                padding: 4px 6px;
            }

            & thead {
                position: sticky;
                top: 0;
                background: #ffffff;
                vertical-align: middle;
            }

            & tbody tr:hover {
                background-color: rgb(216, 216, 216);
            }

            & th {
                padding: 10px 0px 10px 10px;
                color: $heading-color;
            }

            & .th-loading-info-wrap {
                padding-left: 5px !important;
                word-break: keep-all;
            }

            & td {
                color: $text-color;
                padding-left: 10px;
                padding-right: 0;
            }

            & select {
                width: 100%;
                max-width: 170px;
            }

            & input {
                width: 100%;
                max-width: 170px;
            }

            & input[type='checkbox'] {
                width: 22px;
                min-height: 22px;
                border: 1px solid $main-color !important;
                border-radius: 50%;
                -webkit-appearance: none;
                appearance: none;

                &:checked {
                    color: #fff;
                    background: url(../tick_icon.png), $main-color;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 70%;
                }
            }

            & .hedge-edit-icon {
                width: 60px;
            }

            &.add-ingredients-table {
                select {
                    padding-right: 30px;
                }

                select,
                input {
                    max-width: fit-content;
                }
            }

            .variable-split-table input {
                max-width: 100%;
            }

            .variable-split-table tr:nth-of-type(odd) {
                background: rgba(0, 0, 0, 0.3);
            }
            .variable-split-table tr:nth-of-type(even) {
                background: white;
            }
        }

        & .three-blocks {
            margin-top: 15px;
            margin-bottom: 32px;

            & .three-block-inner {
                background-color: #fff;
                border-radius: 10px;
                padding: 10px;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                height: 100%;

                & .block-details {
                    & div {
                        border-bottom: 1px solid #f7f7f7;
                        display: flex;
                        justify-content: space-between;
                        color: #505050;

                        & p {
                            margin: 6px 0px;
                        }
                    }

                    & div:last-child {
                        border-bottom: 0;
                    }

                    & .avg-daily-gain {
                        position: relative;

                        & input {
                            max-width: 100px;
                        }

                        & span {
                            position: absolute;
                            right: 5px;
                            top: 8px;
                            font-size: 14px;
                        }
                    }

                    & .avg-daily-gain + button {
                        padding: 0px 12px;
                    }
                }

                & tr {
                    vertical-align: middle;
                }

                & td {
                    text-align: center;
                }

                & button {
                    padding: 2px 20px;
                    margin-right: 5px;
                }
            }
        }

        & .content-tabs {
            & .white-content-block {
                border-radius: 0px 10px 10px 10px;
                box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.16);

                & .feed-charges-scroll {
                    overflow-y: scroll;
                    overflow-x: hidden;
                    max-height: 150px;
                }

                & .assign-feed-charges-row {
                    & .delete-icon {
                        position: relative;
                        left: 0px;

                        & img {
                            position: absolute;
                            bottom: 6px;
                        }
                    }

                    & .add-row-btn-column {
                        text-align: right;

                        & button {
                            background-color: #242748;
                            color: #fff;
                            border-radius: 10px;
                            padding: 4px 18px;
                            font-size: 12px;
                            font-weight: 400;
                            border: none;
                            height: 26px;
                        }
                    }

                    & .submit-button {
                        position: relative;

                        & button {
                            background-color: #ffa630;
                            color: #242748;
                            border-radius: 10px;
                            padding: 3px 15px;
                            font-weight: 600;
                            border: none;
                            box-shadow: none;
                            position: absolute;
                            bottom: 0;
                            left: 0px;
                        }
                    }
                }
            }

            & .content-tabs-buttons {
                & li {
                    margin-right: -5px;
                }

                & .medical-inventory-tabs li {
                    margin-right: 0px;
                }

                & .medical-inventory-tabs {
                    & + .tab-content .main-content-block .add-btn button {
                        color: $main-color;
                        background-color: $secondary-color;
                        border-radius: 10px;
                        padding: 8px 15px;
                        font-weight: 600;
                        border: none;
                        box-shadow: none;

                        &:hover {
                            background-color: $main-color;
                            color: #fff;
                        }
                    }
                }

                & button {
                    border: none;
                    background-color: $main-color;
                    color: #fff;
                    padding: 10px 12px;
                    font-size: 16px;
                    border-radius: 10px 10px 0px 0px;
                    box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
                    transition: 0.3s;

                    &.active {
                        background-color: #fff;
                        color: $main-color;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    &.new-style {
                        box-shadow: none;
                        border-radius: 8px;
                        font-size: 14px;
                        padding: 8px 12px;
                        margin-right: 10px;
                    }
                }
            }
        }

        & form {
            padding: 20px;

            & .row {
                display: flex;
                align-items: flex-end;
                margin-bottom: 20px;

                & .form-item {
                    width: auto;
                    padding: 0px 20px;
                    min-width: 260px;

                    & h5 {
                        margin-top: 15px;
                        margin-bottom: 0;
                    }

                    & a {
                        font-size: 20px;
                        color: $main-color;
                        -webkit-text-stroke: 0.7px #ffffff;
                    }
                }

                &.submit-buttons {
                    justify-content: space-between;
                    padding: 0px 15px;

                    & div {
                        width: auto;
                    }
                }
            }

            & hr {
                margin: 30px 10px;
            }
        }

        & .filters {
            margin: 20px;
            background-color: #f7f7f7;
            border-radius: 10px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 30px 30px;

            & .form-item {
                margin-left: 30px;

                & select,
                input {
                    min-width: 130px;
                    background-color: #fff;
                }
            }
        }

        & .yard-layout {
            padding: 20px;

            & .yard-layout-sidebar {
                background-color: $main-color;
                padding: 15px;
                border-radius: 10px;
                color: #fff;

                & .form-item label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                & .form-item input {
                    min-width: fit-content;
                    width: 20px;
                    min-height: fit-content;
                    height: 20px;
                    margin-right: 10px;
                }
            }

            & .yard-layout-items {
                background-color: #f7f7f7;
                padding: 15px;
                border-radius: 5px;

                & .row {
                    margin-bottom: 15px;
                }

                & .yard-layout-block {
                    display: flex;
                    flex-direction: column;
                    border-radius: 10px;
                    font-size: 14px;
                    padding: 12px 8px;
                }

                & .row:nth-of-type(even) .yard-layout-block {
                    background-color: #fff;
                }
            }
        }

        & .horizontal-form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #707070;

            & .horizontal-form-left {
                display: flex;
                border: 0;

                & input {
                    min-width: auto;
                    width: 100%;
                }

                & select {
                    min-width: auto;
                    width: 100%;
                }
            }
        }

        & .scheduled-pickups {
            align-items: center;

            & .show-complete {
                display: flex;
                padding-right: 20px;
                align-items: center;

                & label {
                    margin-right: 8px;
                }
            }
        }

        & .user-roles {
            padding: 0px 20px;
            display: flex;
            justify-content: center;

            & .user-roles-name {
                min-width: 200px;
                max-width: 200px;
                min-height: 100px;
                background-color: $main-color;
                color: #fff;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 15px 15px;
                font-size: 18px;
                font-weight: 600;
                transition: 0.4s;
            }
        }

        & .trucking-company-accordion-table {
            & .accordion-item {
                margin-bottom: 0px !important;

                &:nth-child(even) .accordion-button {
                    background: #fff !important;
                }

                & .accordion-button {
                    padding: 4px 15px !important;
                    background-color: #f7f7f7;
                    transition: 0.4s;
                    font-size: 12px;
                    padding-top: 4px;

                    &:not(.collapsed) {
                        box-shadow: none;
                    }

                    &::after {
                        content: '';
                        background-image: none;
                    }
                }

                & .accordion-body {
                    border-radius: 0px 0px 10px 10px;
                    background: #e2e2e2;
                    & .card-body {
                        background-color: #fff;
                        border-radius: 8px;
                        padding: 8px;
                        & h3 {
                            font-weight: bold;
                            color: $main-color;
                            font-size: 1rem;
                        }
                        & table {
                            & thead th {
                                color: $main-color;
                            }

                            & tbody td {
                                padding-top: 4px;
                                padding-bottom: 4px;
                            }
                        }
                    }
                }
            }
        }

        & .trucking-company-list-header {
            color: $main-color;
            font-weight: bold;
            margin: 0;

            & label {
                color: $main-color;
                font-weight: bold;
            }

            & .col-1 {
                padding-left: 0px;

                & img {
                    width: 12px !important;
                    height: auto;
                    position: relative;
                    top: -2px;
                }
            }

            & .col-2 {
                padding-left: 0px;
                & img {
                    width: 12px !important;
                    height: auto;
                    position: relative;
                    top: -2px;
                }
            }
        }

        & .processing-accordion.accordion {
            border-radius: 0;
            border: none;

            .processing-accordion-item:nth-child(odd) {
                background-color: #f7f7f7;
            }

            .processing-accordion-item:nth-child(even) {
                background-color: #fff;
            }
            & .accordion-item {
                border: none !important;

                & .accordion-button {
                    transition: none;
                    box-shadow: none;

                    &[aria-expanded='true'] {
                        border-radius: 0;
                        color: $text-color;
                        background-color: #e2e2e2;
                    }

                    & div {
                        display: flex;
                        align-items: center;
                    }

                    & input {
                        min-height: fit-content;
                    }
                }

                & .accordion-collapse {
                    background-color: #e2e2e2;

                    & .accordion-body {
                        & .card-body {
                            background-color: #fff !important;
                            border-radius: 10px !important;
                            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                        }
                    }
                }
            }
        }

        & .list-group-item-primary.accordion {
            background: none;

            & .accordion-item {
                margin-bottom: 15px;
                border: 0;
                background: none;

                & .accordion-button {
                    background-color: #f2f2f2;
                    border-radius: 10px;
                    padding: 10px 15px;
                    transition: 0.4s;

                    &[aria-expanded='true'] {
                        border-radius: 10px 10px 0px 0px;
                        color: $text-color;
                        background-color: #e2e2e2;
                    }

                    & div {
                        display: flex;
                        align-items: center;
                    }

                    & input {
                        min-height: fit-content;
                    }

                    & .text-right {
                        text-align: right;
                        padding-right: 10px;

                        &::after {
                            filter: brightness(0);
                        }
                    }
                }
            }
        }

        & .feedBills-accordion {
            padding: 0px 30px;

            & .feedBills-accordion-header {
                margin: 0px 0px;
                margin-right: 28px;
            }
        }

        & .page-tabs {
            & .nav {
                border: none;
            }

            & a {
                background-color: $main-color;
                color: #fff;
                padding: 10px 12px;
                font-size: 18px;
                border-radius: 10px 10px 0px 0px;
                box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
                transition: 0.3s;
                margin: 0px 0px 0px -0.5px;

                &:hover {
                    opacity: 0.75;
                }

                &[aria-selected='true'] {
                    background-color: #fff;
                    color: $main-color;
                }

                &.tab-csv-link {
                    border: none;
                    background-color: transparent;
                    padding: 0px;
                    font-size: 0px;
                    border-radius: 0px 0px;
                    box-shadow: none;
                    margin-right: 0px;
                }
            }

            & ul {
                & li {
                    & button {
                        border: none;
                        background-color: $main-color;
                        color: #fff;
                        padding: 10px 12px;
                        font-size: 18px;
                        border-radius: 10px 10px 0px 0px;
                        box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16);
                        transition: 0.3s;

                        &:hover {
                            opacity: 0.75;
                        }

                        &[aria-selected='true'] {
                            background-color: #fff;
                            color: $main-color;
                        }
                    }
                }
            }
            & .billing-button {
                background-color: $secondary-color;
                color: black;

                &:hover {
                    background-color: $main-color;
                    color: white;
                }
            }
        }

        & .page-tabs ~ .white-content-block {
            border-radius: 0px 0px 10px 10px;

            & .list-group-item {
                border: 0;

                &:nth-child(even) {
                    background-color: #f7f7f7;
                }
            }

            &.financial-settings {
                & .col-12 {
                    display: flex;
                    align-items: center;
                    padding: 10px 15px;

                    & h6,
                    & p {
                        margin: 0;
                    }
                }

                & .col-12:nth-child(odd) {
                    background-color: #f7f7f7;
                }
            }
        }

        .uploadedFile {
            border: 1px solid #f2f2f2;
            border-radius: 4px;
            margin-left: 15px;
            margin-bottom: 10px;
            height: fit-content;
            position: relative;
            padding: 5px 15px;
            background: #f2f2f2;
            min-width: 130px;
            max-width: 130px;

            & .text-right {
                position: absolute;
                right: 3px;

                & img {
                    width: 15px;
                    height: 15px;
                }
            }

            & .text-center img {
                height: 30px;
                width: auto;
                margin-bottom: 4px;
            }

            & a {
                color: $main-color;

                &:hover {
                    text-decoration: none;
                    color: $secondary-color;
                }
            }

            & p.wrap-text {
                margin: 0;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        & .check-number-checkboxes {
            & .input-checkbox {
                display: flex;

                & .text-secondary {
                    width: auto;
                    padding-right: 0;

                    & input {
                        width: 32px;
                    }
                }
            }
        }

        .shipPO-lbs-label {
            position: relative;
            top: 5px;
        }

        .export-button-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-bottom: 7px;
        }

        .scale-tab-btns {
            position: absolute;
            width: 50%;
            right: 25px;
            display: none;
            justify-content: flex-end;
            gap: 10px;

            &.active {
                display: flex;
            }
        }

        & .health-protocol-tabs {
            & ul.nav-tabs {
                border: none;
                & li {
                    margin-right: 0px;
                }
            }

            & ul + .tab-content button {
                background-color: $secondary-color;
                color: $main-color;
                border-radius: 10px;
                padding: 8px 15px;
                font-weight: 600;
                border: none;
                box-shadow: none;
            }
        }

        & .health-protocol-buttons {
            a {
                width: fit-content;
                text-align: right;
            }
        }

        & .processing-tabs {
            & ul li button {
                border: none;
                background-color: #242748;
                color: #fff;
                padding: 10px 12px;
                font-size: 18px;
                border-radius: 10px 10px 0px 0px;
                box-shadow: 0px -3px 6px rgb(0 0 0 / 16%);
                transition: 0.3s;

                &.active {
                    background-color: #fff;
                    color: $main-color;
                }
            }

            & .tab-content .white-content-block {
                border-radius: 0px 0px 10px 10px;
                padding: 10px 15px;

                & .lotInfoRow {
                    padding: 15px 0px;

                    &:nth-child(odd) {
                        background-color: #f5f5f5;
                    }
                }
            }

            & .accordion {
                & .accordion-item {
                    margin: 0;
                    border-radius: 0;

                    &:nth-child(odd) .accordion-button {
                        background-color: #f7f7f7;
                        border-radius: 0;

                        & .col-12 {
                            display: flex;
                            align-items: center;
                        }
                    }

                    &:nth-child(even) .accordion-button {
                        background-color: #ffffff;
                    }

                    & .accordion-button[aria-expanded='true'] {
                        background-color: #ccc;
                    }

                    &
                        .accordion-button[aria-expanded='true']
                        + .accordion-collapse {
                        background-color: #ccc;
                    }

                    & .accordion-collapse {
                        & .white-content-block {
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}

.modal {
    background-color: rgba(127, 127, 127, 0.3);

    & .modal-dialog {
        max-width: 50%;
        margin-top: 0;
    }

    &.update-commodity-modal {
        & .modal-dialog {
            max-width: 65%;
        }

        @media screen {
            @media (min-width: 1090px) and (max-width: 1430px) {
                & .modal-dialog {
                    max-width: 92%;
                }
            }
            @media (min-width: 980px) and (max-width: 1090px) {
                & .modal-dialog {
                    max-width: 95%;
                }
            }
        }
    }
    &.create-commodity-modal {
        & .modal-dialog {
            max-width: 70%;
        }
    }

    & .modal-content {
        border: 1px solid #707070;
        border-radius: 10px;
    }

    & .modal-header {
        border: 0;
        padding-bottom: 0;
    }

    & .modal-body-inner {
        & label {
            margin-bottom: 10px;
            margin-right: 10px;
        }

        & input[type='radio'] {
            min-width: 20px;
        }

        & label span {
            position: relative;
            top: -10px;
            left: 7px;
        }

        & select {
            min-width: 170px;
        }
    }

    & .modal-footer {
        border: 0;

        & .blur {
            opacity: 50%;
        }
    }

    input,
    textarea,
    select {
        background-color: #f0f0f0;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        padding: 4px 8px;
        min-height: 32px;
    }

    input[type='checkbox'] {
        min-height: fit-content;
        width: 20px;
        height: 20px;
    }

    button {
        background-color: $secondary-color;
        color: $main-color;
        border-radius: 10px;
        padding: 8px 15px;
        margin: 3px;
        font-weight: 600;
        border: none;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }

    button.secondary {
        background-color: $main-color;
        color: #fff;

        &:hover {
            background-color: $secondary-color;
            color: $main-color;
        }
    }

    & table {
        & input {
            background-color: #f7f7f7;
            border-radius: 4px;
            border: 0;
            padding: 4px 6px;
        }

        & thead {
            position: sticky;
            top: 0;
            background: #ffffff;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
            vertical-align: middle;
        }

        & tr:hover:not(.ignore-highlight) {
            background-color: rgb(216, 216, 216);
        }

        & tr:hover + tr + tr.item-summary {
            background-color: rgb(216, 216, 216);
        }

        & th {
            white-space: nowrap;
            padding: 10px;
            color: $heading-color;
        }

        & td {
            color: $text-color;
            padding: 10px;
            padding-right: 0;
        }

        & select {
            width: 100%;
            max-width: 170px;
        }

        & input {
            width: 100%;
            max-width: 170px;
        }

        & input[type='checkbox'] {
            width: 22px;
            min-height: 22px;
            border: 1px solid $main-color !important;
            border-radius: 50%;
            -webkit-appearance: none;
            appearance: none;

            &:checked {
                color: #fff;
                background: url(../tick_icon.png), $main-color;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 70%;
            }
        }

        & .hedge-edit-icon {
            width: 60px;
        }
    }
}

.add-row-btn {
    text-align: right;
    background-color: #242748 !important;
    color: #fff !important;
    border-radius: 10px !important;
    padding: 4px 18px !important;
    font-size: 12px;
    font-weight: 400 !important;
    border: none !important;
    height: 26px;
}

.edit-user-modal-table {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    .no-hover-effect:hover {
        background-color: transparent !important;
    }
}

.eid-upload-modal {
    & .modal-dialog {
        width: 600px;
    }
}

.eid-upload-input {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 4px;
    width: 300px;
    height: 32px;
}

.backdate-ration-modal {
    & .modal-dialog {
        min-width: fit-content;
    }
}

.treatment-history-modal {
    z-index: 1063;
    & .modal-dialog {
        min-width: 85vw;
    }
}

.confirm-notification-modal {
    & input[type='checkbox'] {
        position: relative;
        top: 5px;
    }
}

.delete-icon {
    width: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default !important;
}

.modal {
    background-color: rgba(127, 127, 127, 0.3);

    & .modal-dialog {
        max-width: 50%;
        margin-top: 0;
    }

    & .modal-content {
        border: 1px solid #707070;
        border-radius: 10px;
    }

    & .modal-header {
        border: 0;
        padding-bottom: 0;
    }

    & .modal-body-inner {
        & label {
            margin-bottom: 10px;
            margin-right: 10px;
        }

        & input[type='radio'] {
            min-width: 20px;
        }

        & label span {
            position: relative;
            top: -10px;
            left: 7px;
        }

        & select {
            min-width: 170px;
        }
    }

    &.feedcall-shipping-modal .modal-dialog {
        max-width: 40%;

        & select {
            &[name='noOfFeedingsBeforeShipment'] {
                width: fit-content;
                padding-right: 40px;
            }
        }
    }

    & .modal-footer {
        border: 0;
    }

    input,
    textarea,
    select {
        background-color: #f0f0f0;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        padding: 4px 8px;
        min-height: 32px;
    }

    button {
        background-color: $secondary-color;
        color: $main-color;
        border-radius: 10px;
        padding: 8px 15px;
        font-weight: 600;
        border: none;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }

    button.secondary {
        background-color: $main-color;
        color: #fff;

        &:hover {
            background-color: $secondary-color;
            color: $main-color;
        }
    }
}

.pen-ration-warning {
    color: #6c757d;
    font-size: 1.1rem;
    margin: 5px 0px;
}

.show-module-disable {
    pointer-events: none;
    opacity: 0.6;
}

.date-range-picker .rdrDateRangePickerWrapper {
    display: none;
}

.date-range-picker .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
    display: none;
}

.date-range-picker .date-range-picker-div:hover .rdrDateRangePickerWrapper {
    display: block;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.date-range-picker .date-range-picker-div input {
    margin: 0.5rem 0px;
    min-width: 181px;
    width: 100%;
}

.date-range-picker .date-range-picker-div input {
    background-color: #f0f0f0 !important;
}

.date-range-picker-div .rdrDateRangePickerWrapper input,
.date-range-picker-div .rdrDateRangePickerWrapper select {
    min-width: auto !important;
    width: auto !important;
    background: none !important;
    border: none;
}

.medicalInventoryClearButton {
    margin-top: 2rem !important;
    margin-right: 2rem !important;
}

.desktop-table-filters-date-range-picker {
    margin-top: -8px;
}

.date-range-picker-div .rdrDateRangePickerWrapper button {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 14px !important;
}

.date-range-picker-div .rdrDateDisplayItemActive {
    border-color: $secondary-color;
}

.date-range-picker-div .rdrDayHovered span {
    border-color: $secondary-color !important;
}

.date-range-picker-div .rdrStartEdge,
.date-range-picker-div .rdrInRange,
.date-range-picker-div .rdrEndEdge {
    background: $secondary-color;
}

.date-range-picker-div .rdrDateRangePickerWrapper input,
.date-range-picker-div .rdrDateRangePickerWrapper select {
    min-width: auto !important;
    width: auto !important;
    background: none !important;
    border: none;
}

.date-range-picker-div .rdrDateRangePickerWrapper button {
    background: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    font-size: 14px !important;
}

.date-range-picker-div .rdrDateDisplayItemActive {
    border-color: $secondary-color;
}

.date-range-picker-div .rdrDayHovered span {
    border-color: $secondary-color !important;
}

.date-range-picker-div .rdrStartEdge,
.date-range-picker-div .rdrInRange,
.date-range-picker-div .rdrEndEdge {
    background: $secondary-color;
}

.lotCard {
    min-height: 20vh;

    h5 {
        font-size: 16px;
    }

    p {
        font-size: 14px;
    }
    display: flex;
    flex-direction: row;
}

@media (min-width: 1920px) {
    .box-overflow {
        overflow-x: scroll;
        width: 100px;
    }

    .monthlist-size {
        width: 81%;
        overflow-x: scroll;
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .monthlist-size::-webkit-scrollbar {
        height: 6px;
    }

    .monthlist-size::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #cccccc;
        visibility: hidden;
    }

    .monthlist-size::-webkit-scrollbar-thumb {
        background: #b9b9b9;
        border-radius: 10px;
    }

    .head-div {
        margin-right: 10px;
    }

    .total-purchase-price {
        margin-left: 120px !important;
    }

    .total-amount-div {
        width: 4vw;
        margin-left: -10px;
    }

    .scroll-horizontal {
        overflow-x: scroll;
        max-width: 111px;
    }

    .lotCard {
        min-height: 24vh;
    }

    .weight-div {
        margin-left: 30px;
    }

    .month-div-spacing {
        margin-left: 5px;
    }
    .purchase-card-design {
        width: 39vw;
        min-height: 200px;
        padding: 3px !important;
    }

    .total-value-div {
        margin-left: 20px;
        font-size: 14px;
        width: 180px;
        overflow: scroll;
        span {
            font-weight: bold;
            font-size: 15px;
            color: $shawna-spreadsheet-heading-color;
        }
    }

    .total-value-div::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .spreadsheet-feedbill-amount {
        margin-left: 192px !important;
        font-size: 12px;
        span {
            font-weight: 400;
            font-size: 14px;
            overflow-x: scroll;
        }
        span::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    .spreadsheet-sold-total {
        margin-left: 220px !important;
        font-size: 12px;
        span {
            font-weight: 400;
            font-size: 14px;
            overflow-x: scroll;
        }
        span::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        .shift-price {
            margin-left: 190px;
            margin-right: 32px;
        }
    }

    #modify-excludelot {
        margin-top: -78px;
        margin-right: 10px;
        display: flex;
    }

    .purchase-card-body {
        height: 200px !important;
    }
}

.feed-border-grey {
    background-color: #e6e6e6;
    min-height: 4rem;
    height: 4rem;
}

.feed-border-white {
    background-color: #eee;
    min-height: 4rem;
    height: 4rem;
}

.bunkTotal_border {
    border-radius: 10px;
    border: 3px solid #e6e6e6;
    border-width: 0.1rem;
}

.border_height_feed {
    min-height: 2rem;
}

.white-spaces {
    white-space: nowrap;
}

/*   Toggle Slider    */
.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: $secondary-color;
}

input:focus + .slider {
    box-shadow: 0 0 1px $secondary-color;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.negative-number {
    color: red;
}

.slider.round:before {
    border-radius: 50%;
}

.disable-checkboxes-toggle {
    position: absolute;
    top: 0;
    right: 40px;

    & .slider {
        background-color: #f7f7f7;
        border: 1px solid $main-color;
    }

    & .slider:before {
        background-color: $secondary-color;
        top: 2px;
    }

    & input:checked + .slider {
        background-color: $main-color;
    }
}

.mobile-top-navigation {
    background-color: $main-color;
    padding: 10px 20px;

    & img {
        height: 46px;
    }

    & .navbar-toggler {
        padding: 0;
        background: none;

        & i {
            color: #ffffff;
            position: relative;
            top: 5px;
        }
    }

    & a {
        color: #ffffff;
        text-align: center;

        &:hover {
            color: $secondary-color;
        }
    }
}

#bg-text {
    color: lightgrey;
    font-size: 150px;
    transform: rotate(320deg);
    -webkit-transform: rotate(320deg);
    margin-left: 0px;
    opacity: 0.3;
    max-height: 0px;
}

.sign-up {
    background-color: #142851;
    min-height: 100vh;

    button {
        border: none;
        background-color: $secondary-color;
        width: 100%;
        color: $main-color;
        padding: 10px 0px;
        border-radius: 4px;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }
    }

    .form-bg-color {
        background-color: rgb(240 240 240);
    }

    .vertical-line {
        border-left: thin solid #707070;
    }
}

.login-container {
    display: grid;
    height: 100vh;
    place-content: center;
    background-color: #142851;

    .bg-light {
        background-color: #f7f7f7 !important;
    }

    .login-block {
        width: 90vw;
        font-size: 18px;
        max-width: 464px;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.06);
        transition: 0.4s;
        color: $heading-color;

        &:hover {
            box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.06);
        }
    }

    .ccc-logo {
        margin-bottom: 30px;
    }

    button {
        border: none;
        background-color: $secondary-color;
        color: $main-color;
        padding: 8px 15px;
        border-radius: 10px;

        &:hover {
            background-color: $main-color;
            color: #fff;
        }

        &:active {
            background-color: $secondary-color;
            color: $main-color;
        }
    }

    a {
        text-decoration: underline;
        color: $heading-color;
    }

    input {
        background-color: #f0f0f0;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        font-size: 18px;
    }
}

.parent-checkbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

input[type='radio'] {
    min-width: 20px;
    cursor: pointer;
}

.child_checkbox {
    display: flex !important;
    margin-right: 1rem !important;
    flex-wrap: wrap !important;
}

.child_checkbox_days {
    margin-top: 0.3rem;
    margin-left: 0.3rem;
}

.feed_call_label {
    white-space: nowrap;
}

.necessaryFields {
    color: #d80000 !important;
}

.route_creation_border {
    border-radius: 10px;
    border: 3px solid #e6e6e6;
    border-width: 0.1rem;
    position: relative;
}

.deleteIcon {
    position: sticky !important;
    top: 0em;
    right: 0em;
    left: 75rem;
}

.rationInput {
    position: absolute !important;
}

/* Product Card Style */

.card-width {
    width: 20rem;
}

.select-processing-protocol-input {
    min-width: 150px;
    max-width: 100%;
    margin-top: 0.55rem;
}

.mortality-report-comments-input {
    resize: none;
}

.add-row-select-pens {
    margin-left: 14rem !important;
}

.feed-call-comments {
    resize: none;
}

.buy-po-comments {
    resize: none;
    height: 140px;
}

.buy-po-notes {
    resize: none;
    height: 50px;
}

.shipping-blur {
    opacity: 50%;
}

.feed-call-white-content {
    border-radius: 4px !important;

    & input,
    & select {
        padding: 0px 4px;
        font-size: 12px;
        min-height: 21px !important;
        max-height: 21px !important;
    }

    select.new-feedcall-pen-info-select {
        width: 70px;
    }

    .new-feed-call_comment-inputbox {
        width: 60%;
    }

    & .total-hd-count-input {
        width: 40px;
    }

    & .pen-selection-input {
        width: 65px;
    }

    & .feed-call-comments {
        min-width: 80%;
    }

    & button {
        padding: 2px 12px;
        padding-top: 3px;
        border-radius: 4px;
    }

    & table {
        margin-bottom: 0;

        & td {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }

        & th {
            padding-top: 2px !important;
            padding-bottom: 2px !important;
        }
    }

    .bunk-score-row {
        display: flex;
        align-items: center;
        position: relative;

        & label {
            margin: 0;
        }

        & .custom-bunk-score-loader {
            position: absolute;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.1);
            width: 100%;
            height: calc(100% + 20px);

            :first-child {
                margin-top: 6px !important;
                margin-bottom: 0px !important;
            }

            .spinner-border {
                width: 1rem;
                height: 1rem;
                margin-top: 12px;
            }
        }
    }

    & .feed-call-hay-per-head {
        & .main-parent-style {
            margin-bottom: 0;
        }
    }

    .feed-call-component {
        & .feed-call-table-scroll-div {
            max-height: 92px;

            & table tbody tr:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.01);

                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    input[name='afChangePerHd'],
    input[name='dmChangePerHd'],
    input[name='afPerHd'],
    input[name='dmPerHd'],
    input[name='wtPerDM'],
    input[name='adg'] {
        font-weight: bold;
    }

    .new-feedcall-pen-info-select option {
        font-weight: bold;
    }
}

.feed-call-table-graph {
    display: flex;
    align-items: flex-start;

    & .white-content-block {
        padding: 2px 0px !important;
        margin-bottom: 12px !important;
    }

    .header-nowrap {
        tr {
            white-space: nowrap !important;
        }
    }

    & th {
        font-size: 12px !important;

        &:first-child {
            padding-right: 6px !important;
        }

        &:nth-child(3) {
            padding-right: 18px !important;
        }
    }

    & .parent-checkbox {
        & .child_checkbox {
            & input[type='radio'] {
                min-height: 12px !important;
                max-height: 12px !important;
                position: relative;
                top: -1px;
            }
        }
    }

    & canvas {
        height: 100% !important;
        padding-bottom: 30px !important;
        object-fit: contain !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .feed-call-table-graph .feed-call-table-responsive {
        width: 60%;
    }

    .feed-call-table-graph .feed-call-graph-responsive {
        width: 40%;
    }
}

.feed-call-main-content {
    padding: 0px 10px;
}

.feed-call-component {
    border-right: 1px solid #dedede;

    & table th {
        padding: 6px 4px !important;
        padding-left: 10px !important;
    }

    & .feed-call-table-scroll-div {
        max-height: 93px !important;
    }
}

.customize-row {
    font-size: 11px !important;
    font-weight: 600 !important;
}

.customize-head {
    font-size: 12px !important;
}

.feed-call-btn {
    font-size: 15px !important;
}

.feed-call-text-header {
    font-size: 13px !important;
    font-weight: 600 !important;
    line-height: 0.2rem !important;
}

.feed-graph-text {
    font-size: 13px !important;
}

.main-parent-style {
    font-size: 11px !important;
}

.padding-feed-module {
    padding: 2px !important;
}

.feed-call-lot-table {
    margin-top: 5px;
}

.feed-call-white-content {
    height: calc(100vh - 100px);
}

.table-head-spacing {
    line-height: 0px !important;
}

.feed-call-hr {
    height: 1px !important;
    margin: 2px !important;
}

.feed-call-input {
    display: flex;
    align-items: center;
}

.bunk-total-feed-call-border {
    border-radius: 6px 0 0 6px;
    border: 3px solid #142851;
    border-width: 0.3rem;
    padding: 2px !important;
    height: max-content !important;

    & .feed_call_label {
        margin: 0px 0px !important;
        font-size: 1rem;
        line-height: 18px;
    }

    & .feed-call-hay-per-head {
        & .main-parent-style {
            margin-bottom: 6px;
        }
    }
}

.feed-call-estimated-hd-count {
    background: $heading-color;
    border-radius: 0 6px 6px 0;
}

.feed-call-estimated-hd-count button {
    font-size: 12px !important;
    font-weight: 800 !important;
}

.feed-call-estimated-hd-count button:hover {
    background-color: $secondary-color !important;
}

.hay-per-head {
    line-height: 0px !important;
}

.feed-graph-wrapper {
    max-height: calc(100vh - 374px);
    width: 100%;
}

.estimated-head-count-add-row {
    position: relative;
}

.estimated-head-count-btn {
    position: absolute;
    right: 30px;
}

.estimated-head-count-btn-mfe {
    position: absolute;
    right: 30px;
}

.custom-width {
    width: 13%;
}

.total-width {
    width: 30.333333%;
}

.total-width-shipment {
    width: 21.333333%;
}

.delete-button {
    width: 3%;
}

@media screen and (max-width: 576px) {
    .custom-width {
        width: 100%;
    }

    .delete-button {
        width: fit-content;
    }
}

.custom-autocomplete-dropdown {
    max-height: 282px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    width: 100%;
}

.custom-autocomplete-dropdown-icons {
    background-color: #f0f0f0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.dropdown-upwards {
    bottom: 100%;
}

.custom-autocomplete-dropdown-delete-icon {
    height: 10px;
}

.custom-autocomplete-dropdown-icons-disabled {
    background-color: #cccccc;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.custom-autocomplete-dropdown-invalid {
    border: 1px red solid !important;
}

.custom-autocomplete-dropdown-small {
    height: 21px;
}

ul.list-group.list-group-striped li:nth-of-type(odd) {
    background: #f0f0f0;
}

ul.list-group.list-group-striped li:nth-of-type(even) {
    background: white;
}

ul.list-group.list-group-hover li.active {
    background: lightgray;
}

.show-toggle {
    margin-right: 16px;

    label {
        background-color: #242748;
        color: white;
        margin-top: 0;

        &.active {
            background-color: #ffa630;
            color: black;
        }

        &:hover {
            background-color: #ffa630;
            color: black;
        }
    }

    input[type='radio'] {
        display: none;
    }
}

.feed-call-component {
    border-right: 1px solid #dedede;

    & .feed-call-table-scroll-div {
        max-height: 93px !important;
    }
}

@media screen and (max-width: 1223px) {
    .feed-call-table-scroll-div {
        max-height: calc(100vh - 360px) !important;
    }
}

.edit-treatment {
    & .main-content {
        & .main-content-block {
            & .white-content-block {
                padding: 10px 0px;

                & input {
                    font-size: 12px !important;
                    min-height: 21px !important;
                    max-height: 21px !important;
                }

                & select {
                    font-size: 12px !important;
                    min-height: 21px !important;
                    max-height: 21px !important;
                    padding: 2px 8px;
                }

                .weight-checkbox {
                    margin-right: 6px;
                }

                & .inner-heading .block-heading {
                    padding-left: 12px;
                }

                & .treatments-form {
                    & .form-label {
                        margin: 0;
                        font-size: 14px;
                    }

                    & .clean-date-block {
                        margin-top: 8px;
                        margin-bottom: 8px;
                        height: fit-content;
                        padding: 8px 0px;

                        & h6 {
                            margin: 0;
                        }
                    }

                    & .give-record-section {
                        margin-top: 20px;

                        & .give-record-header {
                            padding: 4px;

                            & h6 {
                                color: #fff;
                            }

                            & button {
                                padding: 0px 8px;
                                font-size: 14px;
                            }
                        }
                    }

                    & .give-record-body {
                        & div {
                            padding: 2px;
                            font-size: 12px;
                        }
                    }

                    & .history-section {
                        margin-top: 20px;

                        & .history-header {
                            padding: 4px;

                            & h6 {
                                color: #fff;
                            }

                            & button {
                                padding: 0px 8px;
                                font-size: 14px;
                            }
                        }
                    }

                    & .history-body {
                        & div {
                            padding: 2px;
                            font-size: 12px;
                        }
                    }
                }

                & hr {
                    margin: 4px 0px;
                }

                & .chargeout-table {
                    & .scroll-chargeoutTable {
                        font-size: 12px;
                    }
                }

                & .submit-buttons button {
                    font-size: 14px;
                    padding: 4px 8px;
                }
            }
        }
    }
}

.adjustmentHistoryClearFilter {
    margin-top: 2rem !important;
    margin-right: 2rem !important;
}

@media screen and (max-width: 1223px) {
    .feed-call-table-scroll-div {
        max-height: calc(100vh - 374px) !important;
    }
}

.cancel-icon {
    width: 18px;
}

@media screen and (max-width: 767px) {
    .cancel-icon {
        display: none;
    }
}

@media screen and (min-width: 1920px) {
    .terms {
        border-radius: 5px;
        padding: 5px 16px;
        margin-bottom: 10px;

        & .base-weight-label {
            margin-left: 6px;
        }

        & .terms-label {
            font-weight: bold;
        }
    }
}

.yard-layout-total-head-count {
    float: right;
    margin-right: 50px;
}

.feedcall-totalcall {
    line-height: 16px;
    padding: 0px !important;
    font-size: 13px !important;
}

.feedcall-totalcall-input {
    font-size: 20px !important;
    padding: 15px 5px 11px 5px !important;
}

.lot-table-small-td {
    width: 6.9rem;
}
.custom-col-width {
    width: 11.5%;
}

.font-finance {
    font-size: 12px;
}

.finance-font {
    font-size: 14px !important;

    h2 {
        font-size: 20px !important;
    }
}

.ration-confirmation-buttons {
    width: 100px;
}

.ration-confirmation-from-label {
    margin-right: 60px;
}

.ration-confirmation-to-label {
    margin-right: 80px;
}

.ration-change-confirmation-modal {
    max-width: 65%;
    margin-left: 15%;

    & .modal-body {
        padding-bottom: 0;
    }

    & .modal-footer {
        padding-top: 0;
    }
}

.cancellation-confirmation-modal {
    max-width: 800px;
    margin-left: 30%;
}

.ration-confirmation-buttons:nth-child(1) {
    width: 50%;
}

.finance-list-style {
    font-size: 12px;

    input {
        font-size: 12px;
    }

    label {
        font-size: 12px;
    }

    label.btn-secondary {
        font-size: 10px;
    }

    button {
        font-size: 10px;
    }

    .switch {
        width: 40px;
        height: 20px;
    }

    .slider:before {
        height: 14px;
        width: 14px;
    }
}

.loader-overlay {
    z-index: 2;
}

.settings-container {
    background-color: white;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 15px;
    color: $main-color;
    min-height: calc(100vh - 140px);
    display: flex;
    flex-direction: column;

    & input[type='checkbox'] {
        border: 1px solid $main-color !important;
        -webkit-appearance: none;
        appearance: none;
        min-height: 17px;
        color: white;

        &:checked {
            color: #fff;
            background: url(../tick_icon.png), $main-color;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 60%;
        }
    }

    .item-container {
        max-height: calc(100vh - 320px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 6px;
            background-color: #f6f6f6;
            overflow: hidden;
        }

        &::-webkit-scrollbar-thumb {
            background: #cecccc;
            height: 100px;
            border-radius: 20px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .item-container .item-row:nth-child(odd) {
        background-color: #f2f2f2;
    }

    .custom-width {
        width: 45%;
    }
}

.activate-modal .modal-content {
    width: 70%;
    margin: auto;
}

.preline-text {
    white-space: pre-line;
}

.colored-circle {
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 50%;
}

.mfe-input {
    max-width: 63px !important;
}

.display-feedbill-detail {
    border: #e8e8e8 1px solid;
    padding: 7px;
    min-height: 270px;
}

.pens-container {
    background-color: white;
    padding: 5px 20px;
    border-radius: 5px;
    margin: 15px;
    color: $main-color;
    min-height: calc(100vh - 140px);

    button.add-row-btn {
        background-color: $main-color;
        color: #fff;
        border-radius: 10px;
        padding: 4px 18px;
        font-size: 12px;
        font-weight: 400;
        border: none;
        height: 26px;
    }

    h6 {
        font-weight: bold;
    }
}

.main-content-yard-layout .yard-layout-one-pen.yard-layout-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 25px;
}

.yard-layout-card .card-header {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
    z-index: 1;
}

.yard-layout-card {
    padding-top: 20px;
}

.yard-layout-card.card-D10 {
    padding-top: 35px !important;
    padding-left: 25px !important;
}

.yard-layout-card.card-C8 {
    padding-top: 35px !important;
}

.yard-layout-card.card-C1 {
    padding-top: 30px !important;
}

.yard-layout-card.card-B9 {
    padding-top: 30px !important;
}

.yard-layout-card.card-C7 {
    padding-bottom: 30px !important;
}

.yard-layout-card.card-B14 {
    padding-bottom: 30px !important;
}

.yard-layout-card.card-D14,
.yard-layout-card.card-DH,
.yard-layout-card.card-92,
.yard-layout-card.card-91,
.yard-layout-card.card-22,
.yard-layout-card.card-17,
.yard-layout-card.card-16,
.yard-layout-card.card-8,
.yard-layout-card.card-72,
.yard-layout-card.card-20,
.yard-layout-card.card-6,
.yard-layout-card.card-7,
.yard-layout-card.card-74 {
    position: relative;
}

.yard-layout-card.card-D14:before {
    content: '';
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    height: calc(100% + 2px);
    transform: rotate(-22deg);
    position: absolute;
    left: 14px;
}

.yard-layout-card.card-DH:before {
    content: '';
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.125);
    height: calc(100% + 2px);
    transform: rotate(-19deg);
    position: absolute;
    left: 10px;
}

.yard-layout-card.card-C7:before {
    content: '';
    width: 200%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    height: 1px;
    transform: rotate(-31deg);
    position: absolute;
    left: -14%;
    top: 48%;
}

.yard-layout-card.card-B14:before {
    content: '';
    width: 200%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    height: 1px;
    transform: rotate(-37deg);
    position: absolute;
    left: -22%;
    top: 53%;
}

.yard-layout-card.card-74:after {
    content: '';
    background: rgb(27, 116, 12);
    width: 199%;
    height: 60px;
    position: absolute;
    bottom: 100%;
    left: 4%;
    transform: rotate(136deg);
}

.yard-layout-card.card-74:before {
    content: '';
    background: rgb(27, 116, 12);
    width: 200%;
    height: 23px;
    position: absolute;
    top: 129%;
    right: 33%;
    transform: rotate(136deg);
}

.yard-layout-card.card-92:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 93%;
    height: 1px;
    position: absolute;
    top: 130px;
    left: 147px;
    transform: rotate(75deg);
}

.yard-layout-card.card-91:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    position: absolute;
    top: 130px;
    left: -188px;
    transform: rotate(72deg);
}

.yard-layout-card.card-91:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 51%;
    height: 1px;
    position: absolute;
    top: 118px;
    left: 292px;
    transform: rotate(62deg);
}

.yard-layout-card.card-22:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 152%;
    height: 1px;
    position: absolute;
    top: 0px;
    left: -180px;
    transform: rotate(65deg);
}

.yard-layout-card.card-17:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 155%;
    height: 1px;
    position: absolute;
    bottom: 0px;
    right: -102px;
    transform: rotate(31deg);
}

.yard-layout-card.card-17:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 219%;
    height: 1px;
    position: absolute;
    top: 33px;
    left: -104px;
    transform: rotate(91deg);
}

.yard-layout-card.card-16:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    position: absolute;
    top: 41px;
    right: -28px;
    transform: rotate(65deg);
}

.yard-layout-card.card-8:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 149%;
    height: 1px;
    position: absolute;
    left: -50px;
    top: 68px;
    transform: rotate(80deg);
}

.yard-layout-card.card-8:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.yard-layout-card.card-72:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 146%;
    height: 1px;
    position: absolute;
    left: -7px;
    bottom: 12px;
    transform: rotate(15deg);
}

.yard-layout-card.card-20:after {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 40%;
    height: 1px;
    position: absolute;
    right: -17px;
    top: 13px;
    transform: rotate(43deg);
}

.yard-layout-card.card-20:before {
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 123%;
    height: 1px;
    position: absolute;
    left: -129px;
    top: 13px;
    transform: rotate(51deg);
}

.yard-layout-card.card-21:before {
    content: '';
    width: 110%;
    height: 1px;
    position: absolute;
    left: 5%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    top: 56%;
    transform: rotate(41deg);
}

.yard-layout-card.card-6:before {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 119%;
    height: 1px;
    right: 13%;
    bottom: 48%;
    transform: rotate(68deg);
}

.yard-layout-card.card-6:after {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 135%;
    height: 1px;
    left: -4%;
    top: 68%;
    transform: rotate(118deg);
}

.yard-layout-card.card-7:before {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 120%;
    height: 1px;
    right: 11%;
    bottom: 45%;
    transform: rotate(66deg);
}

.yard-layout-card.card-7:after {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.2);
    width: 133%;
    height: 1px;
    left: -6%;
    top: 71%;
    transform: rotate(120deg);
}

.yard-layout-card.card-p3 {
    border: solid 1px rgba(0, 0, 0, 0.25);
}
.yard-layout-card.card-p3 .card-header {
    border: none !important;
    background: transparent !important;
}

.yard-layout-card.card-p3:before {
    position: absolute;
    content: '';
    background: rgb(27, 116, 12);
    width: 57%;
    height: 23px;
    left: -5%;
    top: 20%;
    transform: rotate(26deg);
}

.yard-layout-card.card-p3:after {
    position: absolute;
    content: '';
    background: rgb(27, 116, 12);
    width: 59%;
    height: 23px;
    right: -4%;
    top: 21%;
    transform: rotate(156deg);
}

.yard-layout-one-pen.small-yard-layout-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0 0;
}

.yard-layout-one-pen.small-yard-layout-card .card-title {
    display: flex;
}

.yard-layout-one-pen.small-yard-layout-card .card-title span {
    margin-left: 6px;
}

.yardage-item-detail-container-height {
    max-height: calc(100vh - 285px);
}

.settings-item-detail-container-height {
    max-height: calc(100vh - 388px);
}

.settings-item-detail-container {
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f6f6f6;
        overflow: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background: #cecccc;
        height: 100px;
        border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .custom-icon {
        width: 18px;
    }
}

.settings-item-detail-container .settings-item-detail:nth-child(odd) {
    background-color: #f2f2f2;
}

.pens-container button {
    padding: 5px 15px;
}

.pen-history-modal .modal-dialog {
    max-width: 75%;

    .user-wrapper {
        white-space: 'nowrap';
        overflow: 'hidden';
        text-overflow: 'ellipsis';
    }

    .small-font span:hover {
        white-space: normal;
        overflow: visible;
        text-overflow: unset;
        cursor: pointer;
    }
}

.pen-history-modal-body {
    & .col {
        padding: 0px 8px;
    }
}

.pen-history-table {
    border-radius: 10px;
}

.pen-history-table td {
    font-size: 14px;
    min-width: 85px;
    padding-left: 10px;
}

.pen-history-table td:first-child,
.pen-history-table td:nth-child(2) {
    min-width: 114px;
}

.table-scroll-pen-history {
    max-height: 250px;
    overflow-y: scroll;
    word-break: break-word;
    position: relative;
    border: 1px solid #010b16;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #cccccc;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background: #b5b5b5;
        border-radius: 10px;
    }
}

.table-scroll-hot-cattle {
    max-height: 250px;
    overflow-y: scroll;
    word-break: break-word;
    position: relative;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #cccccc;
        visibility: hidden;
    }

    &::-webkit-scrollbar-thumb {
        background: #b5b5b5;
        border-radius: 10px;
    }
}

.feed-status-buttons {
    padding-top: 2rem;
}

.feed-status-sort-icon {
    width: 20px;
    height: 20px;
    margin-left: 1px;
}

.feed-status-pen-load-header {
    word-break: keep-all;
}

.react-datepicker__day--outside-month {
    color: #d4d4d4;
}

.hide-btn {
    display: none;
}

.past-invoice-sort-icon {
    width: 20px;
    height: 20px;
    margin-left: 1px;
}

.fm-settings-modal .custom-checkbox {
    margin-top: 35px;
}

.custom-checkbox input[type='checkbox'] {
    border: 1px solid $main-color !important;
    -webkit-appearance: none;
    appearance: none;
    min-height: 17px;
    color: white;

    &:checked {
        color: #fff;
        background: url(../tick_icon.png), $main-color;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
    }
}

.yard-layout-card.steer-a-year-pen1 {
    padding-bottom: 100px;
}

.yard-layout-card.yard-box {
    position: relative;
    border: 1px solid $yard-layout-pens-border-color;
}

.yard-layout-card.steer-a-year-pen1:after {
    content: '';
    background: $yard-layout-pens-border-color;
    width: 250px;
    height: 1px;
    position: absolute;
    top: 327px;
    right: -72px;
    transform: rotate(-62.5deg);
}

.yard-layout-card.steer-a-year-pen1:before {
    content: '';
    background: $yard-layout-pens-border-color;
    width: 1px;
    height: 399px;
    position: absolute;
    bottom: 0;
    right: 112px;
}

.yard-layout-card.steer-a-year-pen2:after {
    content: '';
    background: $yard-layout-pens-border-color;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 20px;
    left: -134px;
    transform: rotate(-62.5deg);
}

.yard-layout-card.weiser-penS008:after {
    content: '';
    background: $yard-layout-pens-border-color;
    width: 300px;
    height: 1px;
    position: absolute;
    top: 188px;
    left: -46px;
    transform: rotate(46deg);
}

.yard-layout-card.weiser-penS005:after {
    content: '';
    background: $yard-layout-pens-border-color;
    width: 330px;
    height: 1px;
    position: absolute;
    top: 139px;
    left: -58px;
    transform: rotate(131deg);
}

.header-info-label {
    font-weight: bold;
    margin-right: 5px; /* Adjust as needed for spacing between label and value */
}

.pen-reverse-feedcall-label {
    font-weight: bold;
    margin-left: 4px;
    margin-top: 1px;
}

.loading-button {
    background-color: rgba(255, 166, 48, 0.8) !important;
}

.network-icon {
    position: relative;
    top: -2px;
    display: inline-block;
}

.network-online {
    color: green;
}

.network-offline {
    color: red;
}

.dot-status-indicator {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
}

.highlight-border {
    border: 1px solid #dee2e6 !important;
    border-radius: 5px;
}

.scale-ticket-total-section {
    color: $heading-color;
    font-weight: 700;
}

//remove when upgrading to bootstrap 5.3
.opacity-50 {
    opacity: 0.5;
}

.add-weight-cancel-icon {
    margin-left: -17.3vw !important;
}

.add-term-cancel-icon {
    margin-left: 1.5vh !important;
    margin-right: 4vh !important;
    max-width: 18px;
    max-height: 18px;
}

.add-buttons-height {
    height: '50px';
}

.popover-container {
    width: 100%;
    position: relative;
    display: inline-block;
}

.popover-trigger {
    cursor: pointer;
}

.popover-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
}

.hidden {
    display: none;
    visibility: hidden;
}

.hidden-date {
    visibility: hidden;
}

.popover-content {
    border: 1px solid #efefef;
    opacity: 0;
    visibility: hidden;
    transition:
        opacity 0.25s ease-in-out,
        visibility 0.25s ease-in-out;
}

.popover-wrapper.visible .popover-content {
    opacity: 1;
    visibility: visible;
}

.bg-orange {
    background-color: #ffa630 !important;
}

.rdp-calendar {
    .left-0 {
        left: 10px;
    }
    .right-0 {
        right: 10px;
    }
    tr:hover {
        background-color: transparent !important;
    }
    tr td:hover {
        button {
            border-radius: 0.25rem;
            background-color: #ffa63070;
        }
    }
    button {
        border-radius: 0;
    }
    .weekday {
        color: #ccc;
        width: 2.2543rem;
        border-radius: 0.5rem;
        padding: 0 !important;
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .nav {
        button {
            border-radius: 0.25rem;
            background-color: transparent;
            padding: 0;
        }
    }
    .day-range-start {
        button {
            background-color: $secondary-color !important;
            color: $main-color !important;
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
        }
    }
    .day-range-middle {
        button {
            background-color: $main-color !important;
            color: #fff !important;
            border-radius: 0px;
        }
    }
    .day-range-selected {
        button {
            background-color: $secondary-color;
            color: $main-color;
        }
    }
    .rdp-range_start button {
        background-color: $secondary-color !important;
        color: $main-color;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
    .day-range-end {
        button {
            background-color: $secondary-color !important;
            color: $main-color;
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }
    .day-range-middle.day-range-selected:first-of-type {
        button {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
        }
    }
    .day-range-middle.day-range-selected:last-of-type {
        button {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }
    .outside {
        button {
            background: transparent !important;
            color: $main-color !important;
            opacity: 0.4;
        }
    }
    .outside:hover {
        button {
            background: transparent !important;
            color: $main-color !important;
            opacity: 0.4;
        }
    }
    .hidden {
        visibility: hidden;
    }
}
.font-medium {
    font-weight: 500;
}
.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.day {
    padding: 0 !important;
}
.day button {
    background: transparent;
    padding: 0 !important;
    width: 2.25rem;
    height: 2.25rem;
    text-align: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    position: relative;
}

.date-range-fields {
    background-color: #efefef;
    border-radius: 4px;
    border: 1px solid #dee2e6;
}
.date-range-fields input {
    width: 80px;
    flex: 1;
}

.date-range-fields:focus-within {
    border: 1px solid #007bff;
}
.date-range-fields.danger:focus-within {
    border: 1px solid #dc3545;
}
.date-range-fields.danger {
    border: 1px solid #dc3545;
}

.date-range-fields input {
    outline: none;
    border: none;
    background: transparent !important;
}

.footer-text-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 1rem;
    box-sizing: border-box;
}

.footer-text {
    flex: 1;
    text-align: left;
    word-wrap: break-word;
    max-width: 100%;
    font-size: 14px;
}

.checkbox-align-left {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.hot-cattle-count {
    color: #ff0000 !important;
}

.qwc-password-input-group {
    width: 50%;
}

.qwc-password-eye-icon {
    background: #f0f0f0;
    border: none;
}

.qwc-doc-download-label {
    color: $main-color;
}

.past-invoice-date-range {
    width: 16rem;
}

@media (max-width: 767px) {
    .sidenav,
    .buyPO-filter-block,
    .shippo-filter-block,
    .shippo-table-block {
        display: none;
    }

    .top-navigation-bar {
        display: none !important;
    }

    .modal-dialog {
        min-width: 100%;
        margin: 0;
    }

    .main-container,
    .main-content .main-content-block .outer-heading {
        width: 100%;
    }

    .bg-light.mt-5 {
        margin-top: 0px !important;
        padding-top: 25px;
    }
}
