@import '../../Assets/sass/var';
@import '../../Assets/sass/vendor';

.top-navigation-bar {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);

    & a,
    div.nav-link {
        color: #505050;
        font-size: 18px;
    }

    & .top-header-dropdown {
        & button {
            background: none !important;
            border: none;
            color: $main-color !important;

            &:hover {
                background: none;
                color: $main-color;
            }

            &:focus {
                box-shadow: 0px 0px 0px #fff !important;
            }
        }

        & .dropdown-menu {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
            border: none;
            border-radius: 0px 0px 10px 10px;
            margin-top: 10px;
        }
    }

    & .top-header-language-dropdown {
        & button {
            color: $main-color !important;
        }
    }

    button.top-user-icon {
        border: 2px solid $main-color;
        border-radius: 50%;
        background: none;
        overflow: hidden;
        padding: 0px 4px;
        transition: 0.3s;

        & .fas {
            font-size: 36px;
            position: relative;
            top: 8px;
        }

        &:hover,
        &:focus {
            background-color: $secondary-color;
            background-color: rgba(255, 116, 48, 0.4);

            & .fas {
                color: $main-color;
            }
        }
    }

    & .user-dropdown {
        position: absolute;
        background-color: #ffffff;
        border-radius: 0px 0px 10px 10px;
        min-width: 300px;
        right: 20px;
        top: 63px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        display: none;
        z-index: 4;

        & .user-profile {
            padding: 10px;

            & .inner-user-icon span {
                padding: 0px 4px;
                border: 2px solid #242748;
                border-radius: 50%;
                overflow: hidden;

                & .fas {
                    font-size: 36px;
                    position: relative;
                    top: 8px;
                }
            }
        }

        & .user-profile-detail {
            margin-left: 8px;
            max-width: 300px;

            & span::after {
                content: ',';
            }

            & span:last-child::after {
                content: '.';
            }
        }

        & .dropdown-list {
            & ul {
                padding: 0;
                padding-top: 10px;
                list-style-type: none;
                border-top: 1px solid #dedede;

                & a,
                div.nav-link {
                    color: $main-color;
                }

                & li {
                    padding: 4px 15px;

                    &:hover {
                        background: #f2f2f2;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    button.top-user-icon ~ .user-dropdown.active {
        display: block;
    }
}

nav.breadcrumb {
    background-color: #fff;
    margin: 0;

    & nav {
        --bs-breadcrumb-divider: url(../../Assets/breadcrumb_icon.png);
    }

    & ol {
        margin: 0;

        & li {
            color: $main-color;
            font-size: 16px;

            &:first-child {
                font-weight: 900;
            }

            & a {
                color: $main-color;
                font-size: 16px;
            }
        }
    }
}
